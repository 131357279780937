import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { PageRoutes } from "src/app/enums";
import { environment } from "src/environments/environment";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as FromUser from "src/app/store/user/selectors";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store";
import { TranslateService } from "@ngx-translate/core";
import * as UserActions from "src/app/store/user/actions";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  @Output() public navigate: EventEmitter<string> = new EventEmitter();

  public routes: typeof PageRoutes = PageRoutes;

  constructor(private router: Router, public afAuth: AngularFireAuth, private store: Store<RootState>, private t: TranslateService) { }

  public enablemarketingCookie: boolean = true;
  public enablefuncinalCookie: boolean = true;
  public enableperformanceCookie: boolean = true;
  public asepioImg: boolean = false;
  public userData$: Observable<string> = this.store.select(FromUser.selectUserData);
  public userData: any | undefined;
  public marketingCookieBoolean: boolean = true;

  /**
 * Initializes the component and manages user data and cookie settings.
 * 
 * This method subscribes to the `userData$` observable to obtain user data. Once the user data is received,
 * it performs the following actions after a delay of 2000 milliseconds:
 * 
 * 1. **Check User Tracking Configuration**:
 *    - If `environment.trackUser` is enabled and the user has an `id` but no `marketingCookie`, it updates
 *      the state of the marketing cookie switch and opens the cookies popup.
 *    - If the user has a `marketingCookie`, it updates the switch and cookie state according to the user data.
 * 
 * 2. **Update UI**:
 *    - Sets the checked state of the HTML element with the ID `marketingCookieSwitch` based on the `marketingCookie` value.
 *    - Updates the `enablemarketingCookie` property to match the user's marketing cookie setting.
 * 
 * @throws Will throw an error if `userData$` does not emit a valid value or if the HTML element with the ID `marketingCookieSwitch` is not found.
 */
  public ngOnInit(): void {
    this.userData$.subscribe((val: any) => {
      this.userData = val;

      setTimeout(() => {
        if (environment.trackUser) {
          if (this.userData.id != undefined && this.userData.marketingCookie == undefined) {
            (document.getElementById("marketingCookieSwitch") as HTMLInputElement).checked = true;
            this.enablemarketingCookie = true;
            this.openCookiesPopUp();
          } else {
            environment.trackUser = this.userData.marketingCookie;
            (document.getElementById("marketingCookieSwitch") as HTMLInputElement).checked = this.userData.marketingCookie;
            this.enablemarketingCookie = this.userData.marketingCookie;
          }
        }
      }, 2000);
    })
  }

  /**
 * Opens the specified social media page in a new browser tab based on the given page identifier.
 * 
 * This method takes a page identifier string, converts it to uppercase, and matches it against predefined
 * social media platform names. It then retrieves the corresponding URL from translations and opens it in a new
 * browser tab.
 * 
 * @param page - The identifier for the social media page to navigate to. Accepted values include:
 *   - 'TWITTER'
 *   - 'INSTAGRAM'
 *   - 'LINKEDIN'
 *   - 'FACEBOOK'
 *   - 'YOUTUBE'
 * 
 * @throws {Error} Throws an error if the `page` parameter does not match any of the predefined values. The
 *         method does not handle unknown identifiers explicitly, so no URL will be opened for unmatched values.
 * 
 */
  public goToPage(page: string) {
    let goToPage: string = "";
    switch (page.toUpperCase()) {
      case 'TWITTER': {
        goToPage = this.t.instant("FOOTER.TWITTER");
        break;
      }
      case 'INSTAGRAM': {
        goToPage = this.t.instant("FOOTER.INSTAGRAM");
        break;
      }
      case 'LINKEDIN': {
        goToPage = this.t.instant("FOOTER.LINKEDIN");
        break;
      }
      case 'FACEBOOK': {
        goToPage = this.t.instant("FOOTER.FACEBOOK");
        break;
      }
      case 'YOUTUBE': {
        goToPage = this.t.instant("FOOTER.YOUTUBE");
        break;
      }
    }
    window.open(goToPage, '_blank');
  }

  /**
  * Navigates to the privacy policy page using Angular's Router.
  * 
  */
  public goToPrivacyPolicySection(): void {
    this.router.navigate([this.routes.privacypolicy]);
  }

  /**
  * Navigates to the legal information page using Angular's Router.
  * 
  */
  public goToLegalInformationSection(): void {
    this.router.navigate([this.routes.legalInformation]);
  }

  /**
   * Displays the cookies popup modal.
   * 
  */
  public openCookiesPopUp(): void {
    var modal = document.getElementById("cookiesPopUp")!;
    modal.style.display = "block";
  }

  /**
   * Close the cookies popup modal.
   * 
  */
  public closeCookiesPopUp(): void {
    var modal = document.getElementById("cookiesPopUp")!;
    modal.style.display = "none";
    if (this.userData.id != "") {
      this.updateUserState();
    }
  }

  /**
 * Toggles the marketing cookie setting and updates the environment configuration accordingly.
 * 
 * This method toggles the state of the marketing cookie feature. It switches the `enablemarketingCookie` 
 * property and updates the `environment.trackUser` configuration to reflect the current state of the marketing 
 * cookie setting. Additionally, it updates the `marketingCookieBoolean` property to match the new state.
 * 
 * When the marketing cookie is enabled (`enablemarketingCookie` is `true`):
 * - Sets `environment.trackUser` to `true` to enable tracking.
 * - Updates `marketingCookieBoolean` to `true` to reflect the active state.
 * 
 * When the marketing cookie is disabled (`enablemarketingCookie` is `false`):
 * - Sets `environment.trackUser` to `false` to disable tracking.
 * - Updates `marketingCookieBoolean` to `false` to reflect the inactive state.
 * 
 */
  public marketingCookie(): void {
    if (this.enablemarketingCookie == true) {
      this.enablemarketingCookie = false;
      environment.trackUser = false;
      this.marketingCookieBoolean = false;

      //console.log("desactivar marketing cookie");
    } else {
      this.enablemarketingCookie = true;
      environment.trackUser = true;
      this.marketingCookieBoolean = true;
      //console.log("activar marketing cookie");
    }
  }

  /**
 * Toggles the functional cookie setting.
 * 
 * This method toggles the state of the functional cookie feature by updating the `enablefuncinalCookie` 
 * property. When the functional cookie is enabled (`enablefuncinalCookie` is `true`), it will be disabled 
 * in the next call to this method. Conversely, when the functional cookie is disabled (`enablefuncinalCookie` 
 * is `false`), it will be enabled in the next call to this method.
 * 
 * When the functional cookie is enabled (`enablefuncinalCookie` is `true`):
 * - Sets `enablefuncinalCookie` to `false` to disable the cookie.
 * 
 * When the functional cookie is disabled (`enablefuncinalCookie` is `false`):
 * - Sets `enablefuncinalCookie` to `true` to enable the cookie.
 */
  public funcionalCookie(): void {
    if (this.enablefuncinalCookie === true) {
      this.enablefuncinalCookie = false;
      //console.log("desactivar funcional cookie");
    } else {
      this.enablefuncinalCookie = true;
      //console.log("activar funcional cookie");
    }
  }

  /**
 * Toggles the performance cookie setting.
 * 
 * This method manages the state of the performance cookie feature by updating the `enableperformanceCookie`
 * property. When the performance cookie is enabled (`enableperformanceCookie` is `true`), it will be disabled 
 * in the next call to this method. Conversely, when the performance cookie is disabled (`enableperformanceCookie` 
 * is `false`), it will be enabled in the next call to this method.
 * 
 * When the performance cookie is enabled (`enableperformanceCookie` is `true`):
 * - Sets `enableperformanceCookie` to `false` to disable the cookie.
 * 
 * When the performance cookie is disabled (`enableperformanceCookie` is `false`):
 * - Sets `enableperformanceCookie` to `true` to enable the cookie.
 * 
 */
  public performanceCookie(): void {
    if (this.enableperformanceCookie === true) {
      this.enableperformanceCookie = false;
      //console.log("desactivar performace cookie");
    } else {
      this.enableperformanceCookie = true;
      //console.log("activar performace cookie");
    }
  }

  /**
 * Updates the user's state in the store.
 * 
 * This method dispatches an action to update the user's information in the Redux store. The action carries a payload
 * that includes the new user details, which are taken from the `userData` property. This includes various attributes
 * related to the user, such as personal information, user preferences, and other relevant data.
 * 
 * The dispatched action includes:
 * - `newUserId`: The ID of the user to be updated.
 * - `update`: A string indicating the type of update (e.g., "user").
 * - `user`: An object containing the user's details. This includes:
 *   - `createdAt`: The date and time when the user was created.
 *   - `email`: The user's email address.
 *   - `id`: The user's unique identifier.
 *   - `name`: The user's first name.
 *   - `organisation`: The user's organization.
 *   - `organisationRole`: The role of the user within the organization.
 *   - `sessionState`: The current state of the user's session.
 *   - `myNews`, `myRealCases`, `myDevelopment`, `myCardio3d`, `myInteractiveImage`, `myEcg`, `myPatient`, 
 *     `mySession`, `myActiveSession`, `myCartoCases`, `myDicom`: Various attributes related to the user's data and 
 *     preferences in different areas.
 *   - `image`: The user's profile image.
 *   - `password`: The user's password.
 *   - `surname`: The user's surname.
 *   - `uid`: A unique identifier for the user.
 *   - `webRole`: The user's role on the web platform.
 *   - `likes`: The user's likes.
 *   - `dislikes`: The user's dislikes.
 *   - `connectedDevices`: Information about devices connected to the user's account.
 *   - `associatedDas`: Associated data or entities.
 *   - `deadline`: A deadline associated with the user.
 *   - `marketingCookie`: Indicates if the marketing cookie setting is enabled.
 *   - `smsVerification`: Status of SMS verification for the user.
 *   - `processingSession`: Status of session processing.
 *   - `streak`: User streak information.
 *   - `lastRecordDate`: The date of the last recorded activity.
 *   - `phone`: The user's phone number.
 *   - `country`: The user's country.
 *   - `physicalLocation`: The user's physical location.
 * 
 * This method assumes that the `store` object is properly configured with the `UserActions` and that `userData` 
 * contains the relevant user details. It is typically used when user information needs to be updated in the store 
 * after changes have been made.
 * 
 */
  public updateUserState() {
    this.store.dispatch(
      UserActions.updateUser({
        newUserId: this.userData.id,
        update: "user",
        user: {
          createdAt: this.userData.createdAt,
          email: this.userData.email,
          id: this.userData.id,
          name: this.userData.name,
          organisation: this.userData.organisation,
          organisationRole: this.userData.organisationRole,
          sessionState: this.userData.sessionState,
          myNews: this.userData.myNews,
          myRealCases: this.userData.myRealCases,
          myDevelopment: this.userData.myDevelopment,
          myCardio3d: this.userData.myCardio3d,
          myInteractiveImage: this.userData.myInteractiveImage,
          myEcg: this.userData.myEcg,
          myPatient: this.userData.myPatient,
          mySession: this.userData.mySessions,
          myActiveSession: this.userData.myActiveSession,
          myCartoCases: this.userData.myCartoCases,
          myDicom: this.userData.myDicom,
          image: this.userData.image,
          password: this.userData.password,
          surname: this.userData.surname,
          uid: this.userData.uid,
          webRole: this.userData.webRole,
          likes: this.userData.likes,
          dislikes: this.userData.dislikes,
          connectedDevices: this.userData.connectedDevices,
          associatedDas: this.userData.associatedDas,
          deadline: this.userData.deadline,
          marketingCookie: this.marketingCookieBoolean,
          smsVerification: this.userData.smsVerification,
          processingSession: this.userData.processingSession,
          streak: this.userData.streak,
          lastRecordDate: this.userData.lastRecordDate,
          phone: this.userData.phone,
          country: this.userData.country,
          physicalLocation: this.userData.physicalLocation,
          coins: this.userData.coins
        },
      })
    );
  }
}
