<div class="header">

    <!-- <div class="header__header" id="headerFlagSelection">
        <button class="header__dropdown__item" (click)="toggleDropdown()">
            <img class="header__dropdown__item__flag" id="enCurrentFlagImage" src="assets/icons/en.webp" alt="">
            <img class="header__dropdown__item__flag" id="esCurrentFlagImage" src="assets/icons/es.webp" alt="">
            <img class="header__dropdown__item__flag" id="arCurrentFlagImage" src="assets/icons/ar.webp" alt="">
        </button>
        <div class="header__dropdown__content" id="languageDropdown">
            <a href="#" id="header__dropdown__content__flag__en" class="header__dropdown__content__flag" (click)="changeLanguage('en')"><img src="assets/icons/en.webp" alt="" class="header__dropdown__content__flag__img"></a>
            <a href="#" id="header__dropdown__content__flag__es" class="header__dropdown__content__flag" (click)="changeLanguage('es')"><img src="assets/icons/es.webp" alt="" class="header__dropdown__content__flag__img"></a>
            <a href="#" id="header__dropdown__content__flag__ar" class="header__dropdown__content__flag" (click)="changeLanguage('ar')"><img src="assets/icons/ar.webp" alt="" class="header__dropdown__content__flag__img"></a>
        </div>
    </div> -->

    <div class="header__left">
        <img class="header__left__img" src="assets/icons/VC_Small.png" alt="" (click)="goToPage('DAS', userName)">
    </div>


    <div *ngIf="userName != ''" class="header__center">
        <div class="header__center__text">
            <span class="header__center__text__label" id="headerDas" [innerText]="'HEADER.DAS' | translate"
                (click)="goToPage('DAS', userName)"></span>
            <i *ngIf="isDasPage" class="header__center__text__dot fa fa-circle" aria-hidden="true"></i>
        </div>
        <div class="header__center__text">
            <span class="header__center__text__label" id="headerRecord" [innerText]="'HEADER.RECORD' | translate"
                (click)="goToPage('RECORD', userName)"></span>
            <i *ngIf="isRecordPage" class="header__center__text__dot fa fa-circle" aria-hidden="true"></i>
        </div>
        <div class="header__center__text">
            <span class="header__center__text__label" id="headerSessions"  [innerText]="'HEADER.SESSIONS' | translate"
                (click)="goToPage('SESSIONS', userName)"></span>
            <i *ngIf="isSessionsPage" class="header__center__text__dot fa fa-circle" aria-hidden="true"></i>
        </div>
    </div>
    <div *ngIf="userName == ''" class="header__center">
        <div class="header__center__text">
            <span class="header__center__text__label" id="headerDas" [innerText]="'HEADER.DAS' | translate"
                (click)="goToPage('DAS', userName)"></span>
            <i *ngIf="isDasPage" class="header__center__text__dot fa fa-circle" aria-hidden="true"></i>
        </div>
    </div>

    <div class="header__right">
        <i class="header__right__cog fa fa-cog" aria-hidden="true" (click)="openConfig()"></i>
        <img *ngIf="(userImage$ | async) != undefined && (userImage$ | async) != ''" class="header__right__img"
            [src]="(userImage$ | async)" (click)="openMenu()">
        <img *ngIf="(userImage$ | async) == undefined || (userImage$ | async) == ''" class="header__right__img"
            src="assets/icons/avatar.png" (click)="openMenu()">

        <div id="header__right__dropdown" class="header__right__dropdown">
            <div class="header__right__dropdown__out">
                <span class="header__right__dropdown__out__icon"><i class="fa fa-user"
                        aria-hidden="true"></i></span>
                <span class="header__right__dropdown__label" [innerText]="'HEADER.PROFILE' | translate"
                    (click)="goToPage('PROFILE', userName)"></span>
            </div>
            <div class="header__right__dropdown__out">
                <span class="header__right__dropdown__out__icon" (click)="signOut()"><i class="fa fa-sign-out"
                        aria-hidden="true"></i></span>
                <span class="header__right__dropdown__out__label" [innerText]="'HEADER.LOGOUT' | translate"
                    (click)="signOut()"></span>
            </div>
        </div>
        <div id="header__right__dropdown__unlogged" class="header__right__dropdown__unlogged">
            <div class="header__right__dropdown__unlogged__group" style="display: flex; flex-direction: row; column-gap: 10px;">
                <i class="header__right__dropdown__unlogged__icon fa fa-sign-in"></i>
                <span class="header__right__dropdown__unlogged__label" [innerText]="'HEADER.SIGNIN' | translate"
                    (click)="goToPage('SIGNIN', userName)"></span>
            </div>
            <div class="header__right__dropdown__unlogged__group" style="display: flex; flex-direction: row; column-gap: 10px;">
                <i class="header__right__dropdown__unlogged__icon fa fa-user-plus"></i>
                <span class="header__right__dropdown__unlogged__label" [innerText]="'HEADER.SIGNUP' | translate"
                    (click)="goToPage('SIGNUP', userName)"></span>
            </div>
        </div>
    </div>

    <div class="header__config" *ngIf="config"> 
        <div class="header__config__menu">
            <div class="header__config__menu__content" (click)="openSettings()">
                <i class="header__config__menu__content__icon fa fa-cog"></i>
                <span class="header__config__menu__content__span" [innerText]="'HEADER.ACCOUNTSETTING' | translate"></span>
            </div>
            <div class="header__config__menu__content" (click)="openFeedback()">
                <i class="header__config__menu__content__icon fa fa-comment"></i>
                <span class="header__config__menu__content__span" [innerText]="'HEADER.GIVEFEEDBACK' | translate"></span>
            </div>
            <div class="header__config__menu__content" (click)="openAboutUs()">
                <i class="header__config__menu__content__icon fa fa-info"></i>
                <span class="header__config__menu__content__span" [innerText]="'HEADER.ABOUT' | translate"></span>
            </div>
        </div>
    </div>

    <div class="header__update" id="updatePopUp">
        <span class="header__update__label" id="popUpText"></span>
        <i class="header__update__icon fa fa-times" aria-hidden="true" (click)="deletePopUp()"></i>
      </div>
</div>