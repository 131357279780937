/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponentModule } from "./components/footer/footer.module";
import { HeaderComponentModule } from "./components/header/header.module";
import { DEFAULT_LANGUAGE } from "./constants";
import { createTranslateLoader } from "./services/i18n/translate-loader";
import { localStorageSync } from "ngrx-store-localstorage";
import { persistedKeys as user } from "./store/user/state";
import { ApiModule, Configuration } from "./services/api";
import { ActionReducer, MetaReducer, Store, StoreModule } from "@ngrx/store";
import { reducers, rootEffects, RootState } from "./store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
import { environment } from "src/environments/environment";
//import { AuthInterceptor } from "./interceptors/auth/auth.interceptor";
import { logoutMetaReducer } from "./store/user/meta-reducer";
import { appInitializer } from "./app.initializer";
import { AngularFireAnalyticsModule, ScreenTrackingService } from "@angular/fire/compat/analytics";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { AngularFireModule } from "@angular/fire/compat";
import { CarouselModule } from "ngx-carousel-lib";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingComponent } from "./components/loading/loading.component";
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';


export function localStorageReduxSync(reducer: ActionReducer<RootState>): ActionReducer<any> {
  return localStorageSync({
    keys: [{ user }, /*{ news }*/],
    rehydrate: true,
  })(reducer);
}

export function setApiConfig(): Configuration {
  return new Configuration({ basePath: environment.api });
}

const metaReducers: MetaReducer<any, any>[] = [localStorageReduxSync, logoutMetaReducer];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    EffectsModule.forRoot(rootEffects),
    TranslateModule.forRoot({
      defaultLanguage: DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ApiModule.forRoot(setApiConfig),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    HeaderComponentModule,
    FooterComponentModule,
    CarouselModule,
    BrowserAnimationsModule
  ],
  providers: [
    ScreenTrackingService,
    { provide: APP_INITIALIZER, useFactory: appInitializer, deps: [HttpClient, Store, Router, AngularFireAuth], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }