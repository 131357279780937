import { Store } from "@ngrx/store";
import { RootState } from "./store";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { trackUser } from "src/app/services/api/trackUser";

export const appInitializer = (http: HttpClient, store: Store<RootState>, router: Router, afAuth: AngularFireAuth): (() => Promise<void>) =>
  async (): Promise<void> => {
    let userTrack: trackUser = new trackUser(http, store, router, afAuth);
    if (environment.trackUser) {
      userTrack.trackUserActivity();
    }
  };