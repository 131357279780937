import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonComponentModule } from "../button/button.module";
import { FooterComponent } from "./footer.component";

@NgModule({
  imports: [CommonModule, TranslateModule, ButtonComponentModule],
  declarations: [FooterComponent],
  exports: [FooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class FooterComponentModule { }
