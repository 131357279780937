/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageRoutes } from "./enums";
import { SwaggerUiComponent } from "./swagger-doc/swagger-ui.component";
//import { LoginGuard } from "./guards/login.guard";

const routes: Routes = [
  {
    path: PageRoutes.swagger,
    component: SwaggerUiComponent
  },
  {
    path: PageRoutes.login,
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginPageModule),
  },
  {
    path: PageRoutes.profile,
    loadChildren: () => import("./pages/profile/profile.module").then(m => m.ProfilePageModule),
  },
  {
    path: "das",
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomePageModule),
  },
  {
    path: "home",
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomePageModule),
  },
  {
    path: "",
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginPageModule),
  },
  {
    path: PageRoutes.signUp,
    loadChildren: () => import("./pages/signup/signup.module").then(m => m.SignUpPageModule),
  },
  {
    path: PageRoutes.privacypolicy,
    loadChildren: () => import("./pages/privacypolicy/privacypolicy.module").then(m => m.PrivacyPolicyModule),
  },
  {
    path: PageRoutes.resetPassword,
    loadChildren: () => import("./pages/resetPassword/resetPassword.module").then(m => m.resetPasswordPageModule),
  },
  {
    path: PageRoutes.legalInformation,
    loadChildren: () => import("./pages/legalInformation/legalInformation.module").then(m => m.LegalInformationModule),
  },
  {
    path: PageRoutes.restorePassword,
    loadChildren: () => import("./pages/restorePassword/restorePassword.module").then(m => m.restorePasswordPageModule),
  },
  {
    path: PageRoutes.record,
    loadChildren: () => import("./pages/record/record.module").then(m => m.RecordPageModule),
  },
  {
    path: PageRoutes.sessions,
    loadChildren: () => import("./pages/sessions/sessions.module").then(m => m.SessionsPageModule),
  },
  {
    path: "**",
    redirectTo: PageRoutes.login
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top", useHash: true })],
  exports: [RouterModule],
})

export class AppRoutingModule { }
