import { Environment } from "src/app/interfaces";

export const environment: Environment = {
  production: true,
  api: "https://vrcardionow-default-rtdb.firebaseio.com/prod",
  firebaseStorageMaxSize: 2147483648, //bytes 2147483648 => 2GB
  maxSecondsRecording: 130,
  firebase: {
    apiKey: "AIzaSyD-iRAwfnXmBKbz5K3KLDwd7h906vqJCDw",
    authDomain: "vrcardionow.firebaseapp.com",
    projectId: "vrcardionow",
    storageBucket: "vrcardionow.appspot.com",
    messagingSenderId: "145868914712",
    appId: "1:145868914712:web:fe3c9fd3a7a38bf9e0036d",
    measurementId: "G-30VEDL5KT0"
  },
  trackUser: true,
  timeToTrackUser: 60000,
  sampleRate: 250,
  showUpdateTableMessage: false,
  userAmount: {
    basicAmount: "1.00",
    professionalAmount_1Month: "2.50",
    professionalAmount_3Month: "6.50",
    professionalAmount_1Year: "25"
  },
  availableImageFormats: ["PNG", "JPG", "JPEG", "PNG", "GIF", "SVG", "WEBP", "BMP", "ICO", "TIFF", "TIF", "PSD"],
  swaggerURL: "https://firebasestorage.googleapis.com/v0/b/vrcardionow.appspot.com/o/swagger.json?alt=media&token=29698d84-e581-42a6-8cec-371c5a77b182",

  getencrypteduser: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getencrypteduser',
  checklogin: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/checklogin',
  getsingleuserdata: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getsingleuserdata',
  encryptpatients: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/encryptpatients',
  getsinglepatient: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getsinglepatient',
  getsimplifieddeviceid: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getsimplifieddeviceid',
  updatepatientdasdata: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/updatepatientdasdata',
  savebasesequences: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/savebasesequences',
  launchpipeline: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/launchpipeline',
  getsinglesession: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getsinglesession',
  updatevestdasdata: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/updatevestdasdata',
  getsessions: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getsessions',
  getsessionsusingindexes: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getsessionsusingindexes',
  getvestdas: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getvestdas',
  encryptbasicuser: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/encryptbasicuser',
  checkuser: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/checkuser',
  registeruser: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/registeruser',
  deleteuser: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deleteuser',
  updateuser: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/updateuser',
  sendMail: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/sendMail',
  saverestorepasswordcode: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/saverestorepasswordcode',
  checkrestorecode: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/checkrestorecode',
  createstats: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/createstats',
  encryptstats: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/encryptstats',
  updateuserpassword: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/updateuserpassword',
  updateuseremail: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/updateuseremail',
  updateuseremailandpassword: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/updateuseremailandpassword',
  updatesessionsarraydata: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/updatesessionsarraydata',
  updateuserdata: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/updateuserdata',
  updateusername: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/updateusername',
  encryptvestdas: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/encryptvestdas',
  createvestdas: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/createvestdas',
  recordsession: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/api/vr_cardio/recordsessionapp',
  cancelrecordsession: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/api/vr_cardio/cancelrecordsessionapp',
  createpatient: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/createpatient',
  deletevestsessionrecorded: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deletevestsessionrecorded',
  deletesessionfrompatient: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deletesessionfrompatient',
  deletesession: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deletesession',
  deleteusercontent: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deleteusercontent',
  addimagetosession: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/addimagetosession',
  launchreport: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/launchreport',
  getactivevests: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getactivevests',
  getconfig: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getconfig',
  getmanypipelinereport: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getmanypipelinereport',
  getpipelinereport: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getpipelinereport',
  setconfig: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/setconfig',
  resetwifivest: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/resetwifivest',
  openvrcardio: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/openvrcardio',
  getallusers: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getallusers',
  getuser: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getuser',
  deleteuserfromauth: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deleteuserfromauth',
  getwebstats: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getwebstats',
  getbannedmacs: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getbannedmacs',
  getsinglepipelinereport: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getsinglepipelinereport',
  deletevestdas: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deletevestdas',
  recordsessionstate: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/api/vr_cardio/recordsessionstate',
  getsignal: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getsignal',
  getactivelicensesmacs: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getactivelicensesmacs',
  deleteactivesmacs: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deleteactivesmacs',
  getallsignals: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getallsignals',
  deleteaccountinfo: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deleteaccountinfo',
  savesignals: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/savesignals',
  deleteaccount: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deleteaccount',
  checkpendingupdates: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/checkpendingupdates',
  appsinglesession: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/api/vr_cardio/sessions/',
  appsinglesessionexplore: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/api/vr_cardio/sessions/',
  getsessionindexes: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getsessionindexes',
  getsessionindexesexplore: 'https://europe-west1-vrcardioexplorer.cloudfunctions.net/app/getsessionindexes',
  pipelinetoken: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/pipelinetoken',
  getpipestats: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getpipestats',
  createuserassociatedas: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/createuserassociatedas',
  getsinglevestdas: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getsinglevestdas',
  deleteuserassociatedas: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/deleteuserassociatedas',
  getcurrentsessionid: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getcurrentsessionid',
  signalStatus: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/signalstatus',
  launchprodpipeline: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/launchprodpipeline',
  getinputsandoutputs: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getinputsandoutputs',
  getCommitsApiUrl: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getcommits',
  getReleasesApiUrl: 'https://europe-west1-vrcardionow.cloudfunctions.net/app/getreleases',
  adminUrl: "http://itserver.spikatech.com:3000",
  releasesRepo: "/get-releases/",
  commitsRepo: "/get-commits/",
  changeValue: "/updatefield/",
  runmultiplepipeline: "/runmultiplepipeline/",
  pipelineIp: {
    visualCardioDev:"http://itserver.spikatech.com:5000", // Pipeline no repo segmentation
    visualCardioProd:"http://itserver.spikatech.com:5000", // Pipeline repo segmentation
    vrCardioExploreDev:"http://itserver.spikatech.com:5001", // Pipeline no repo segmentation
    vrCardioExploreProd:"http://itserver.spikatech.com:5001", // Pipeline repo segmentation
  },
  pipelineAvailable: {
    visualCardio:{
      prod: true,
      dev: false
    },
    vrCardioExplore:{
      prod: true,
      dev: false
    }
  }
}