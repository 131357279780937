import "@angular/compiler";
import { Component, SimpleChanges } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as FromUser from "src/app/store/user/selectors";
import { RootState } from "src/app/store";
import * as cryptoJS from 'crypto-js';
import { Router, NavigationEnd, NavigationCancel, NavigationStart, ActivationEnd } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/compat/auth';


@Component({
  template: ''
})

export class trackUser  {
  public userEmail$ : Observable<string> = this.store.select(FromUser.selectEmail);
  public userData$: Observable<string> = this.store.select(FromUser.selectUserData);
  public userData: any | undefined;
  public userEmail : string | undefined;
  public ipData : any;

  constructor(private http:HttpClient, private store: Store<RootState>, private router: Router, public afAuth: AngularFireAuth) {
    this.userData$.subscribe((val:any)=>{
      this.userData = val;
    })
  }

  async ngOnChanges(changes: SimpleChanges){
    this.userData$.subscribe((val:any)=>{
      this.userData = val;
    })
  }

  private async encryptStatsID(auxIpData: any, auxDatetime:any){
    let requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: auxIpData.ip + auxDatetime })
    };

    let result: any = await fetch(environment.encryptstats, requestOptions);
    let resultJson = await result.json();
   return resultJson[0];
  }

  public async trackUserActivity() {
    let ipData: any;
    let actualPage: any;
    let idUser: any;
    let dataPagesVisited: any = {};
    let initialTime: any = Date.now();
    let finalTime: any;
    var d = new Date();
    let initialDateFixed = [d.getDate(),
               d.getMonth()+1,
               d.getFullYear()].join('/')+', '+
              [d.getHours(),
               d.getMinutes(),
               d.getSeconds()].join(':');
    let initialDate: any = (new Date()).toLocaleString().trim();
    let statsId: any;
    let result: any;
    let maxIters : number = 0;

    this.http.get<{ ip: string }>('https://jsonip.com').subscribe((data: { ip: any; }) => {
      this.http.get("http://ip-api.com/json/" + data.ip).subscribe(async (res: any) => {
        result = res;
      });
    })

    var interval = setInterval(async () => {
      if (maxIters == 10 || result != undefined) {
        clearInterval(interval);
        //User IP
        ipData = result !== undefined ? {
          ip: result.query,
          city: result.city,
          country: result.country
        } : "not found" 

        //Stats ID
        statsId = await this.encryptStatsID(ipData, initialDate).then((val:any)=>{return val.toUpperCase();});

        //Initial page
        actualPage = this.router.url.substring(1).split('/')[0].toUpperCase();
        if (actualPage == "") {
          actualPage = "HOME"
        }

        let calculateTime: boolean = true;
        //Detect page change
        this.router.events.subscribe(event => {
          if (event instanceof ActivationEnd) {
            if (calculateTime) {
              calculateTime = false;
              finalTime = Date.now();
              const timeSpend = finalTime - initialTime;

              if (!dataPagesVisited.hasOwnProperty(actualPage)) {
                dataPagesVisited[actualPage] = timeSpend;
              } else {
                dataPagesVisited[actualPage] = timeSpend;
              }

              actualPage = this.router.url.substring(1).split('/')[0].toUpperCase();
              if (actualPage == "") {
                actualPage = "HOME"
              }

              initialTime = Date.now();

              setTimeout(() => {
                calculateTime = true;
              }, 100);
            }
          }
        });

        //Get userId
        this.userEmail$.subscribe((val: any) => {
          this.userEmail = val.toUpperCase();
        });

        if (this.userEmail == "") {
          idUser = "-"
        } else {
          const url = environment.getencrypteduser + `?requestParameters=${encodeURIComponent(JSON.stringify({ email: this.userEmail!.toUpperCase() }))}`;
          idUser = await fetch(url).then(response => response.json());
        }
      }else{
        maxIters += 1;
      }
    }, environment.timeToTrackUser / 12);

    //Push the data
    const updateData = async () => {
      finalTime = Date.now();
      const timeSpend = finalTime - initialTime;
      if (!dataPagesVisited.hasOwnProperty(actualPage)) {
        dataPagesVisited[actualPage] = timeSpend;
      } else {
        dataPagesVisited[actualPage] = timeSpend;
      }

      let userTrack: any = {
        date: initialDateFixed,
        ipData: ipData,
        page: dataPagesVisited,
        userId: idUser,
        statsId: statsId
      }
      
      if (this.userEmail === "") {
        userTrack.userId = idUser;
      } else {
        userTrack.userId = idUser.encryptedUser !== undefined ? idUser.encryptedUser.toLocaleUpperCase() : idUser;
      }
      
      if(environment.trackUser){
        try{
          if(this.userData.email != ""){
            let uid = ""
            const userRecord = await this.afAuth.signInWithEmailAndPassword(this.userData.email,this.userData.password).then((val: any)=>{
              uid = val.user!.uid;
            })
            let url = environment.createstats + `?requestParameters=${encodeURIComponent(JSON.stringify(userTrack))}`;
            let result: any = await fetch(url,{
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${uid}` // Include the UID in the Authorization header
              }
            })
          }else{
            let url = environment.createstats + `?requestParameters=${encodeURIComponent(JSON.stringify(userTrack))}`;
            let result: any = await fetch(url,{
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              }
            })
          }
        } catch(error){
          console.log(error);
        }
      }else{
        clearInterval(interval);
      }
    }

    setInterval(updateData, environment.timeToTrackUser);
  }
}
