export enum Language {
  es = "es",
  en = "en",
  ar = "ar"
}

export enum PageRoutes {
  das = "das",
  newId = "newid",
  login = "login",
  privacypolicy = "privacypolicy",
  legalInformation = "legalinformation",
  signUp = "signup",
  resetPassword = "resetpassword",
  restorePassword = "restorepassword",
  profile = "profile",
  swagger = "swagger",
  quiz = "quiz",
  record = "record",
  sessions = "sessions",
}
