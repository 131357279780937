import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as UserActions from "./actions";

export const userReducer = createReducer(
  initialState,

  on(UserActions.login, state => ({ ...state, loading: true })),

  on(UserActions.registerUser, state => ({ ...state, loading: true })),

  on(UserActions.checkLogin, state => ({ ...state, loading: true })),

  on(UserActions.loginFailure, state => ({ ...state, loading: false })),

  on(UserActions.registerUserFailure, UserActions.registerUserSuccess, state => ({ ...state, loading: false })),

  on(UserActions.loginSuccess, (state, { user }) => {
    let newNews = [];
    if (user.myNews != undefined) {
      newNews = user.myNews;
    }
    let newrealCases = [];
    if (user.myRealCases != undefined) {
      newrealCases = user.myRealCases;
    }
    let newDevelopment = [];
    if (user.myDevelopment != undefined) {
      newDevelopment = user.myDevelopment;
    }
    let newCardio3d = [];
    if (user.myCardio3d != undefined) {
      newCardio3d = user.myCardio3d;
    }
    let newinteractiveImage = [];
    if (user.myInteractiveImage != undefined) {
      newinteractiveImage = user.myInteractiveImage;
    }
    let newecg = [];
    if (user.myEcg != undefined) {
      newecg = user.myEcg;
    }
    let newpatient = [];
    if (user.myPatient != undefined) {
      newpatient = user.myPatient;
    }
    let newsession = [];
    if (user.mySession != undefined) {
      newsession = user.mySession;
    }
    let newactivesession = [];
    if (user.myActiveSession != undefined) {
      newactivesession = user.myActiveSession;
    }
    let newcartocases = [];
    if (user.myCartoCases != undefined) {
      newcartocases = user.myCartoCases;
    }
    let newdicom = [];
    if (user.myDicom != undefined) {
      newdicom = user.myDicom;
    }
    let likes = [];
    if (user.likes != undefined) {
      likes = user.likes;
    }
    let dislikes = [];
    if (user.dislikes != undefined) {
      dislikes = user.dislikes;
    }
    let connectedDevices = [];
    if (user.connectedDevices != undefined) {
      connectedDevices = user.connectedDevices;
    }
    let associatedDas = [];
    if (user.associatedDas != undefined) {
      associatedDas = user.associatedDas;
    }
    return {
      ...state,
      createdAt: user.createdAt,
      email: user.email,
      id: user.id,
      name: user.name,
      organisation: user.organisation,
      organisationRole: user.organisationRole,
      sessionState: user.sessionState,
      myNews: newNews,
      myRealCases: newrealCases,
      myDevelopment: newDevelopment,
      myCardio3d: newCardio3d,
      myInteractiveImage: newinteractiveImage,
      myEcg: newecg,
      myPatient: newpatient,
      mySession: newsession,
      myActiveSession: newactivesession,
      myCartoCases: newcartocases,
      myDicom: newdicom,
      password: user.password,
      image: user.image,
      surname: user.surname,
      uid: user.uid,
      webRole: user.webRole,
      likes: likes,
      dislikes: dislikes,
      connectedDevices: connectedDevices,
      associatedDas: associatedDas,
      deadline: user.deadline,
      loading: true,
      marketingCookie: user.marketingCookie,
      phone: user.phone,
      smsVerification: user.smsVerification,
      processingSession: user.processingSession,
      streak: user.streak,
      lastRecordDate: user.lastRecordDate,
      country: user.country,
      physicalLocation: user.physicalLocation,
      coins: user.coins
    }
  }),

  on(UserActions.updateUserSuccess, (state, { user }) => {
    let newNews = [];
    if (user.myNews != undefined) {
      newNews = user.myNews;
    }
    let newrealCases = [];
    if (user.myRealCases != undefined) {
      newrealCases = user.myRealCases;
    }
    let newDevelopment = [];
    if (user.myDevelopment != undefined) {
      newDevelopment = user.myDevelopment;
    }
    let newCardio3d = [];
    if (user.myCardio3d != undefined) {
      newCardio3d = user.myCardio3d;
    }
    let newinteractiveImage = [];
    if (user.myInteractiveImage != undefined) {
      newinteractiveImage = user.myInteractiveImage;
    }
    let newecg = [];
    if (user.myEcg != undefined) {
      newecg = user.myEcg;
    }
    let newpatient = [];
    if (user.myPatient != undefined) {
      newpatient = user.myPatient;
    }
    let newsession = [];
    if (user.mySession != undefined) {
      newsession = user.mySession;
    }
    let newactivesession = [];
    if (user.myActiveSession != undefined) {
      newactivesession = user.myActiveSession;
    }
    let newcartocases = [];
    if (user.myCartoCases != undefined) {
      newcartocases = user.myCartoCases;
    }
    let newdicom = [];
    if (user.myDicom != undefined) {
      newdicom = user.myDicom;
    }
    let likes = [];
    if (user.likes != undefined) {
      likes = user.likes;
    }
    let dislikes = [];
    if (user.dislikes != undefined) {
      dislikes = user.dislikes;
    }
    let connectedDevices = [];
    if (user.connectedDevices != undefined) {
      connectedDevices = user.connectedDevices;
    }
    let associatedDas = [];
    if (user.associatedDas != undefined) {
      associatedDas = user.associatedDas;
    }
    return {
      loading: false,
      createdAt: user.createdAt,
      email: user.email,
      id: user.id,
      name: user.name,
      organisation: user.organisation,
      organisationRole: user.organisationRole,
      sessionState: user.sessionState,
      myNews: newNews,
      myRealCases: newrealCases,
      myDevelopment: newDevelopment,
      myCardio3d: newCardio3d,
      myInteractiveImage: newinteractiveImage,
      myEcg: newecg,
      myPatient: newpatient,
      mySession: newsession,
      myActiveSession: newactivesession,
      myCartoCases: newcartocases,
      myDicom: newdicom,
      password: user.password,
      image: user.image,
      surname: user.surname,
      uid: user.uid,
      webRole: user.webRole,
      likes: likes,
      dislikes: dislikes,
      connectedDevices: connectedDevices,
      associatedDas: associatedDas,
      deadline: user.deadline,
      marketingCookie: user.marketingCookie,
      phone: user.phone,
      smsVerification: user.smsVerification,
      processingSession: user.processingSession,
      streak: user.streak,
      lastRecordDate: user.lastRecordDate,
      country: user.country,
      physicalLocation: user.physicalLocation,
      coins: user.coins
    }
  }),

  on(UserActions.updateUserFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(UserActions.checkLoginSuccess, (state, { output }) => {
    return {
      ...state, loading: false
    }
  }),
);
