import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { RootState } from "./store";
import * as UserActions from "./store/user/actions";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(public router: Router, private store: Store<RootState>, private t: TranslateService) { }

  public logout(): void {
    if (confirm(this.t.instant("HEADER.confirmLogout"))) this.store.dispatch(UserActions.logout());
  }
}
