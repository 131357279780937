import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { PageRoutes } from "src/app/enums";
import { RootState } from "src/app/store";
import * as FromUser from "src/app/store/user/selectors";
import * as UserActions from "src/app/store/user/actions";
import Swal from 'sweetalert2';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Output() public navigate: EventEmitter<string> = new EventEmitter();
  @Output() public logout: EventEmitter<void> = new EventEmitter();

  public routes: typeof PageRoutes = PageRoutes;
  public isSessionsPage: boolean = false;
  public isRecordPage: boolean = false;
  public isDasPage: boolean = false;
  public userName: string = "";
  public config: boolean = false;

  public userID$: Observable<string> = this.store.select(FromUser.selectUserID);
  public userName$: Observable<string> = this.store.select(FromUser.selectUserName);
  public userImage$: Observable<string> = this.store.select(FromUser.selectUserImage);
  public isHomeAvailable: boolean = false;
  public dropMenu: boolean = true;

  constructor(private router: Router, private t: TranslateService, private store: Store<RootState>) {
    router.events.subscribe((val) => {
      this.isDasPage = router.url.includes(PageRoutes.das);
      this.isRecordPage = router.url.includes(PageRoutes.record);
      this.isSessionsPage = router.url.includes(PageRoutes.sessions);
    });
  }

  public async ngOnInit(): Promise<void> {
    this.userName$.subscribe((val: any) => {
      this.userName = val;
    })

    /*let flagEn : any= document.getElementById('header__dropdown__content__flag__en');
    let flagEs : any= document.getElementById('header__dropdown__content__flag__es');
    let flagAr : any= document.getElementById('header__dropdown__content__flag__ar');
    let currentFlagEs : any= document.getElementById('esCurrentFlagImage');
    let currentFlagEn : any= document.getElementById('enCurrentFlagImage');
    let currentFlagAr : any= document.getElementById('arCurrentFlagImage');
    let languageDropdown : any= document.getElementById('languageDropdown');
    languageDropdown.style.display = "none";
    let languageCode = this.t.defaultLang;
    switch(languageCode){
      case 'en':{
        flagEn.style.filter = "grayscale(0.5)";
        flagEs.style.filter = "grayscale(1)";
        flagAr.style.filter = "grayscale(1)";
        currentFlagEs.style.display = "none";
        currentFlagEn.style.display = "flex";
        currentFlagAr.style.display = "none";
        currentFlagEn.style.filter = "grayscale(0.5)";
        break;
      }
      case 'es':{
        flagEn.style.filter = "grayscale(1)";
        flagEs.style.filter = "grayscale(0.5)";
        flagAr.style.filter = "grayscale(1)";
        currentFlagEs.style.display = "flex";
        currentFlagEn.style.display = "none";
        currentFlagAr.style.display = "none";
        currentFlagEs.style.filter = "grayscale(0.5)";
        break;
      }
      case 'ar':{
        flagEn.style.filter = "grayscale(1)";
        flagEs.style.filter = "grayscale(0.5)";
        flagAr.style.filter = "grayscale(1)";
        currentFlagEs.style.display = "none";
        currentFlagEn.style.display = "none";
        currentFlagAr.style.display = "flex";
        currentFlagAr.style.filter = "grayscale(0.5)";
        break;
      }
    }*/

    let requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    };

    try {
      let result = await fetch(environment.checkpendingupdates, requestOptions);
      let range = await result.json();

      let currentDate = new Date();
      let dateUTCPlus1 = new Date(currentDate.getTime() + (1 * 60 * 60 * 1000));

      function formatDate(isoDateString: any) {
        const date = new Date(isoDateString);

        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      }


      function getDaysDifference(date1: any, date2: any) {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        return (date2 - date1) / millisecondsPerDay;
      }

      function checkDateStatus(currentDate: any, start: any, end: any) {
        const startDate = new Date(start);
        const endDate = new Date(end);

        // It is ongoing
        if (currentDate >= startDate && currentDate <= endDate) {
          const style = document.createElement('style');
          style.innerHTML = `
              .confirm-button-class {
                color: #000000 !important;  
              }
              .custom-swal-popup {
                border: 2px solid #01fefe;  
                border-radius: 10px;  
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); 
              }
            `;
          document.head.appendChild(style);

          Swal.fire({
            position: 'center',
            icon: 'error',
            title: "The web will be under maintenance from " + formatDate(range.start) + " to " + formatDate(range.end),
            showConfirmButton: false,
            timer: 86400000,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            backdrop: `rgba(0, 0, 0, 0.9)`,
            background: '#111f21',
            color: "#ffffff",
            customClass: {
              confirmButton: 'confirm-button-class',
              popup: 'custom-swal-popup',
            }
          }).then((result) => {
          });
        }

        // Less than a day to start
        const daysUntilStart = getDaysDifference(currentDate, startDate);
        if (daysUntilStart > 0 && daysUntilStart < 1) {
          (document.getElementById("updatePopUp") as HTMLElement).style.display = "flex";
          (document.getElementById("popUpText") as HTMLElement).innerHTML = "The web will be under maintenance from " + formatDate(range.start) + " to " + formatDate(range.end);
        }
      }

      checkDateStatus(dateUTCPlus1, range.start, range.end);

    } catch (error) {
      console.log(error)
    }

  }

  public deletePopUp(){
    (document.getElementById("updatePopUp") as HTMLElement).style.display = "none"
  }

  /**
 * Navigates to a specified page based on the given page identifier and user status.
 * 
 * This method handles navigation based on the `page` parameter, which specifies the target page, and the `user` parameter, which determines if the user is logged in.
 * It also manages the display of dropdown menus in the header.
 * 
 * The method performs the following steps:
 * 1. Sets `this.dropMenu` to `true`, indicating that a dropdown menu should be displayed.
 * 2. Hides two specific dropdown menu elements in the header by setting their display styles to "none".
 * 3. Uses a `switch` statement to handle navigation based on the `page` parameter.
 * 4. For certain pages (`RECORD`, `SESSIONS`, `PROFILE`), it checks if the `user` parameter is defined and not empty.
 *    - If the user is logged in (i.e., `user` is not `undefined`, `null`, or an empty string), it navigates to the corresponding route.
 *    - If the user is not logged in, it redirects to the login page.
 * 5. For other pages (`DAS`, `SIGNIN`, `SIGNUP`), it navigates directly to the specified route without checking the `user` parameter.
 * 
 * The `page` parameter should be one of the following strings:
 * - `"DAS"`: Navigates to the DAS page.
 * - `"RECORD"`: Navigates to the record page if the user is logged in, otherwise redirects to the login page.
 * - `"SESSIONS"`: Navigates to the sessions page if the user is logged in, otherwise redirects to the login page.
 * - `"PROFILE"`: Navigates to the profile page if the user is logged in, otherwise redirects to the login page.
 * - `"SIGNIN"`: Navigates to the sign-in (login) page.
 * - `"SIGNUP"`: Navigates to the sign-up page.
 * 
 * @param {string} page - The identifier for the target page.
 * @param {string} user - The current user identifier or `null` if the user is not logged in.
 */
  public goToPage(page: string, user: string) {
    this.dropMenu = true;
    (document.getElementById("header__right__dropdown") as HTMLElement).style.display = "none";
    (document.getElementById("header__right__dropdown__unlogged") as HTMLElement).style.display = "none";
    switch (page) {
      case "DAS": {
        this.router.navigate([this.routes.das]);
        break;
      }
      case "RECORD": {
        if (user != undefined && user != null && user != "") {
          this.router.navigate([this.routes.record]);
        } else {
          this.router.navigate([this.routes.login]);
        }
        break;
      }
      case "SESSIONS": {
        if (user != undefined && user != null && user != "") {
          this.router.navigate([this.routes.sessions]);
        } else {
          this.router.navigate([this.routes.login]);
        }
        break;
      }
      case "PROFILE": {
        if (user != undefined && user != null && user != "") {
          this.router.navigate([this.routes.profile]);
        } else {
          this.router.navigate([this.routes.login]);
        }
        break;
      }
      case "SIGNIN": {
        this.router.navigate([this.routes.login]);
        break;
      }
      case "SIGNUP": {
        this.router.navigate([this.routes.signUp]);
        break;
      }
    }
  }

  /**
 * Toggles the visibility of the configuration panel and hides dropdown menus.
 * 
 * This method performs the following actions:
 * 1. Toggles the state of the `config` property between `true` and `false`. This is used to show or hide the configuration panel.
 * 2. If the `dropMenu` property is `true` (indicating that a dropdown menu is open), it sets `dropMenu` to `false`, effectively closing the dropdown menu.
 * 3. Hides the dropdown menu elements in the header by setting their display styles to "none":
 *    - `header__right__dropdown`: The main dropdown menu element.
 *    - `header__right__dropdown__unlogged`: The dropdown menu element shown when the user is not logged in.
 * 
 * This method ensures that when the configuration panel is opened, any visible dropdown menus are hidden to maintain a clean UI.
 */
  public openConfig() {
    this.config = !this.config;
    if (this.dropMenu) {
      this.dropMenu = false;
    }
    (document.getElementById("header__right__dropdown") as HTMLElement).style.display = "none";
    (document.getElementById("header__right__dropdown__unlogged") as HTMLElement).style.display = "none";
  }

  /**
 * Displays a confirmation dialog for signing out and handles the sign-out process.
 * 
 * This method performs the following actions:
 * 1. **Style Injection**:
 *    - Creates and injects a `<style>` element into the document's head to customize the appearance of the SweetAlert2 (Swal) popup.
 *    - Sets styles for:
 *      - **Confirm Button**: Ensures the button text color is black and overrides default styles.
 *      - **Popup**: Customizes the popup with a border, border radius, and shadow to enhance its visual appearance.
 * 
 * 2. **Confirmation Dialog**:
 *    - Uses SweetAlert2 (`Swal.fire`) to display a confirmation dialog with:
 *      - A title and text extracted from the internationalization service (`this.t.instant`).
 *      - Warning icon.
 *      - Confirm and cancel buttons with customized colors and texts.
 *      - Dark background and white text color for the popup.
 *      - Custom classes applied to the confirm and cancel buttons, as well as the popup itself.
 * 
 * 3. **Sign-Out Handling**:
 *    - If the user confirms the action (`result.value` is true):
 *      - Dispatches the `UserActions.logout()` action to handle the sign-out logic.
 *      - Sets `dropMenu` to `true` and hides dropdown menu elements by setting their display styles to "none".
 * 
 * This method ensures that the user is prompted for confirmation before signing out, and it handles the UI updates to reflect the sign-out state.
 * 
 */
  public signOut() {
    const style = document.createElement('style');
    style.innerHTML = `
          .confirm-button-class {
            color: #000000 !important;  
          }
          .custom-swal-popup {
            border: 2px solid #01fefe;  
            border-radius: 10px;  
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);  
          }
        `;
    document.head.appendChild(style);
    Swal.fire({
      title: this.t.instant('HEADER.SIGNOUT'),
      text: this.t.instant('HEADER.SIGNOUTTEXT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#01fefe',
      confirmButtonText: this.t.instant('HEADER.YES'),
      cancelButtonColor: '#01fefe',
      cancelButtonText: this.t.instant('HEADER.NO'),
      background: '#111f21',
      color: "#ffffff",
      customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'confirm-button-class',
        popup: 'custom-swal-popup',
      }
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(UserActions.logout());
        this.dropMenu = true;
        (document.getElementById("header__right__dropdown") as HTMLElement).style.display = "none";
        (document.getElementById("header__right__dropdown__unlogged") as HTMLElement).style.display = "none";
      }
    });
  }

  /**
 * Toggles the visibility of the user menu or the unlogged menu based on the current state and user login status.
 * 
 * This method performs the following actions:
 * 1. **User Menu Handling**:
 *    - Checks if `this.userName` is not an empty string to determine if the user is logged in.
 *    - If the user is logged in:
 *      - **Toggle Dropdown Menu**:
 *        - If `this.dropMenu` is `true` (menu is currently visible), sets it to `false` and changes the display style of the user menu to "flex".
 *        - If `this.dropMenu` is `false` (menu is currently hidden), sets it to `true` and hides the user menu by setting its display style to "none".
 *    - If the user is not logged in:
 *      - **Toggle Unlogged Dropdown Menu**:
 *        - If `this.dropMenu` is `true` (menu is currently visible), sets it to `false` and changes the display style of the unlogged menu to "flex".
 *        - If `this.dropMenu` is `false` (menu is currently hidden), sets it to `true` and hides the unlogged menu by setting its display style to "none".
 * 
 * 2. **Config Handling**:
 *    - Sets `this.config` to `false` if it is currently `true`, ensuring the config menu is closed when the user menu is toggled.
 * 
 * This method allows toggling between the user menu and the unlogged menu based on the user's login status and updates the visibility accordingly.
 * 
 */
  public openMenu() {
    if (this.userName != "") {
      if (this.dropMenu) {
        this.dropMenu = false;
        (document.getElementById("header__right__dropdown") as HTMLElement).style.display = "flex";
      } else {
        this.dropMenu = true;
        (document.getElementById("header__right__dropdown") as HTMLElement).style.display = "none";
      }
    } else {
      if (this.dropMenu) {
        this.dropMenu = false;
        (document.getElementById("header__right__dropdown__unlogged") as HTMLElement).style.display = "flex";
      } else {
        this.dropMenu = true;
        (document.getElementById("header__right__dropdown__unlogged") as HTMLElement).style.display = "none";
      }
    }

    if (this.config) {
      this.config = false;
    }
  }

  public openSettings() {
    this.router.navigate([this.routes.login]);
  }

  public openFeedback() {
    this.router.navigate([this.routes.legalInformation]);
  }

  public openAboutUs() {
    this.router.navigate([this.routes.privacypolicy]);
  }

  /*public changeLanguage(languageCode:string) {
    //console.log('Language changed to ' + languageCode);
    this.t.use(languageCode);
    this.t.setDefaultLang(languageCode);
    let flagEn : any= document.getElementById('header__dropdown__content__flag__en');
    let flagEs : any= document.getElementById('header__dropdown__content__flag__es');
    let flagAr : any= document.getElementById('header__dropdown__content__flag__ar');
    let currentFlagEs : any= document.getElementById('esCurrentFlagImage');
    let currentFlagEn : any= document.getElementById('enCurrentFlagImage');
    let currentFlagAr : any= document.getElementById('arCurrentFlagImage');
    let languageDropdown : any= document.getElementById('languageDropdown');
    languageDropdown.style.display = "none";
    switch(languageCode){
      case 'en':{
        flagEn.style.filter = "grayscale(0.5)";
        flagEs.style.filter = "grayscale(1)";
        flagAr.style.filter = "grayscale(1)";
        currentFlagEs.style.display = "none";
        currentFlagEn.style.display = "flex";
        currentFlagAr.style.display = "none";
        currentFlagEn.style.filter = "grayscale(0.5)";
        break;
      }
      case 'es':{
        flagEn.style.filter = "grayscale(1)";
        flagEs.style.filter = "grayscale(0.5)";
        flagAr.style.filter = "grayscale(1)";
        currentFlagEs.style.display = "flex";
        currentFlagEn.style.display = "none";
        currentFlagAr.style.display = "none";
        currentFlagEs.style.filter = "grayscale(0.5)";
        break;
      }
      case 'ar':{
        flagEn.style.filter = "grayscale(1)";
        flagEs.style.filter = "grayscale(1)";
        flagAr.style.filter = "grayscale(0.5)";
        currentFlagEs.style.display = "none";
        currentFlagEn.style.display = "none";
        currentFlagAr.style.display = "flex";
        currentFlagAr.style.filter = "grayscale(0.5)";
        break;
      }
    }
  }

  public toggleDropdown() {
    let dropdownContent : any= document.getElementById('languageDropdown');
    let headerFlagSelection : any= document.getElementById('headerFlagSelection');
    this.hideOnClickOutside(headerFlagSelection)
    dropdownContent.style.display = (dropdownContent.style.display === 'flex') ? 'none' : 'flex';
  }

  public  hideOnClickOutside(element : any) {
    const outsideClickListener = (event:any) => {
      if (!element.contains(event.target) && this.isVisible(element)) {
        let languageDropdown : any= document.getElementById('languageDropdown');
        languageDropdown.style.display = 'none';
        removeClickListener();
      }
    }

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
    }

    document.addEventListener('click', outsideClickListener);
  }

  public isVisible(elem:any) : boolean{
    return !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length ); 
  }*/
}
