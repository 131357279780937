import { Component, EventEmitter, Input, Output } from "@angular/core";

export type ButtonType = "clear" | "outline" | "solid";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() public text!: string;
  @Input() public disabled!: boolean;
  @Input() public color!: string;
  @Input() public type!: ButtonType;
  @Input() public loading!: boolean;
  @Input() public icon!: string;
  @Input() public titleInfo!: string;

  @Output() public clickEvent: EventEmitter<void> = new EventEmitter();

  constructor() { }
}
