import { createAction, props } from "@ngrx/store";
import { LoginBody } from "src/app/services/api";

export const checkLogin = createAction("[User] Check Login", props<{ loginBody: LoginBody }>());
export const login = createAction("[User] Login", props<{ loginBody: LoginBody }>());
export const loginSuccess = createAction("[User] Login Success", props<{ user: any }>());
export const checkLoginSuccess = createAction("[User] Login Success", props<{ output: any }>());
export const loginFailure = createAction("[User] Login Failure");
export const updateUser = createAction("[User] User Update", props<{ update: string, user: any, newUserId: string }>());
export const updateUserSuccess = createAction("[User] Update User Success", props<{ user: any }>());
export const updateUserFailure = createAction("[User] Update User Failure", props<{ reason: string }>());
export const logout = createAction("[User] Logout");
export const registerUser = createAction("[User] Register", props<{ user: any }>());
export const registerUserSuccess = createAction("[User] Register Success", props<{ user: any }>());
export const registerUserFailure = createAction("[User] Register Failure");
export const deleteUser = createAction("[User] User Delete", props<{ user: any, uid: string }>());
export const deleteUserSuccess = createAction("[User] Delete User Success", props<{ user: any }>());
export const deleteUserFailure = createAction("[User] Delete User Failure", props<{ reason: string }>());
